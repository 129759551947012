import React from 'react';
import { Routes, Route } from 'react-router-dom';

import {
    ROUTE_EXPLORE,
    ROUTE_EXPLORE_PLAYBOOKS,
    ROUTE_EXPLORE_TRACK,
    ROUTE_TARGET,
    ROUTE_TARGET_MY_COMPANY,
} from 'pages/constants';

import MainLayout from 'layouts/main';
import AuthorizedRoute from 'components/base/AuthorizedRoute';
import Playbooks from 'pages/app/playbooks';
import Track from 'pages/app/track';

const AppRouter = () => {
    return (
        <Routes>
            <Route path={ROUTE_EXPLORE} element={<MainLayout />}>
                <Route element={<AuthorizedRoute action="playbooks.view" />}>
                    <Route index path={ROUTE_EXPLORE_PLAYBOOKS} element={<Playbooks />} />
                </Route>
                <Route element={<AuthorizedRoute action="track.view" />}>
                    <Route index path={ROUTE_EXPLORE_TRACK} element={<Track />} />
                </Route>
            </Route>

            <Route path={ROUTE_TARGET} element={<MainLayout />}>
                <Route element={<AuthorizedRoute action="myCompany.view" />}>
                    <Route index path={ROUTE_TARGET_MY_COMPANY} element={<Track />} />
                </Route>
            </Route>
        </Routes>
    );
};

export default AppRouter;
