import { useInfiniteQuery } from '@tanstack/react-query';

import api from 'services/api';

const fetchPlaybooks = async ({ pageParam = 1, filters }) => {
    const query = new URLSearchParams({
        page: pageParam
    });

    if (filters.search) {
        query.append('segment_name', filters.search);
    }

    if (filters.lifecycle) {
        query.append('lifecycle', filters.lifecycle);
    }

    try {
        const response = await api.get(`api/v1/plays?${query.toString()}`);
        const {
            data: { payload, meta },
        } = await response.json();

        return { data: payload, meta };
    } catch (e) {
        return Promise.reject(e);
    }
};

const usePlaybooks = ({ filters,  enabled= true,}) => {
    return useInfiniteQuery({
        queryKey: ['playbooks', filters],
        queryFn: ({ pageParam = 1 }) => fetchPlaybooks({ pageParam, filters }),
        enabled,
        getNextPageParam: ({ meta: { next_page } }, allPages) => {
            if (next_page === 0) return undefined;
            return next_page;
        },
    });
};

export default usePlaybooks;
