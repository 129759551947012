import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { Step, StepLabel, Stepper, Box } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import GrainIcon from '@mui/icons-material/Grain';
import SupportIcon from '@mui/icons-material/Support';
import InfoIcon from '@mui/icons-material/Info';
import ForumIcon from '@mui/icons-material/Forum';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { useTranslation } from 'react-i18next';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import usePlaySteps from 'hooks/playbooks/usePlaySteps';
import { serialize } from 'components/elements/MdxRemote/serialize';
import { MDXRemote } from 'components/elements/MdxRemote';
import OrderedListItem from 'components/elements/OrderedListItem';
import EmailVariable from 'components/elements/EmailVariable';
import ConversationListItem from 'components/elements/ConversationListItem';
import StepHeader from 'components/elements/StepHeader';
import UnorderedListItem from 'components/elements/UnorderedListItem';
import EmailTemplate from 'components/elements/EmailTemplate';
import Paragraph from 'components/elements/Paragraph';
import Header1 from 'components/elements/Header1';
import ProgressTracker from 'components/elements/ProgressTracker';
import StepBody from 'components/elements/StepBody';
import Header2 from 'components/elements/Header2';
import Conversation from 'components/elements/Conversation';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import FeedbackSurveyIcon from 'components/elements/FeedbackSurveyIcon';
import WorkshopIcon from 'components/elements/WorkshopIcon';
import TrainingGuideIcon from 'components/elements/TrainingGuideIcon';
import RecommendationIcon from 'components/elements/RecommendationIcon';

const ExecutePlayDialog = ({ open, handleClose, height }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [mdx, setMdx] = useState(null);
  const [steps, setSteps] = useState([]);
  const [navigatedSteps, setNavigatedSteps] = useState([]);
  const [stepsCopied, setStepsCopied] = useState([]);

  const { data, status } = usePlaySteps(open.playId);
  const [isEmailTemplate, setIsEmailTemplate] = useState(false);

  const colors = ['#743ae9', '#f28c37', '#49ac43', '#743ae9', '#f28c37', '#49ac43'];

  const components = {
    p: props => <p {...props} />,
    h1: props => <h1{...props} />,
    OrderedListItem: props => <OrderedListItem color={colors[activeStep]} {...props} />,
    EmailVariable,
    ConversationListItem: props => <ConversationListItem color={colors[activeStep]} {...props} />,
    StepHeader: props => <StepHeader color={colors[activeStep]} {...props} />,
    EmailIcon,
    MeetingRoomIcon,
    GrainIcon,
    SupportIcon,
    InfoIcon,
    ForumIcon,
    QuestionAnswerIcon,
    FeedbackSurveyIcon,
    WorkshopIcon,
    TrainingGuideIcon,
    RecommendationIcon,
    EmailTemplate: props => <EmailTemplate {...props} />,
    Paragraph,
    Header1,
    Header2,
    ProgressTracker: props => <ProgressTracker color={colors[activeStep]} {...props} />,
    StepBody: props => <StepBody {...props} height={height - 268} />,
    UnorderedListItem: props => <UnorderedListItem color={colors[activeStep]} {...props} />,
    Conversation,
  };

  useEffect(() => {
    if (data && data.data) {
      setSteps(data.data.play_steps);
    }
  }, [data]);

  useEffect(() => {
    if (data && data.data && steps.length > 0) {
      serialize(data.data.play_steps[activeStep].step_content).then(r => {
        setMdx(r);
      });
    }
  }, [steps, activeStep]);

  useEffect(() => {
    if (data && data.data && steps.length > 0) {
      if (data.data.play_steps[activeStep].step_content.includes('EmailTemplate')) {
        setIsEmailTemplate(true);
      } else {
        setIsEmailTemplate(false);
      }
    }
  }, [steps, activeStep]);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    if (!navigatedSteps.includes(activeStep)) {
      setNavigatedSteps([...navigatedSteps, activeStep]);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
    if (!navigatedSteps.includes(activeStep)) {
      setNavigatedSteps([...navigatedSteps, activeStep]);
    }
  };

  const handleStepClick = (current) => {
    setActiveStep(current);
  };

  const handleCopy = () => {
    if(document) {
      let template = document.querySelector('#email-template').textContent;
      navigator.clipboard.writeText(`${template}`);
      let updatedStepCopied = [...stepsCopied]
      if(!stepsCopied.includes(activeStep)){
        updatedStepCopied.push(activeStep)
      }
      setStepsCopied(updatedStepCopied)
    }
  };

  const getVariant = (action) =>{
    if(action==='next'){
      return isEmailTemplate ?'outlined':'contained';
    }else if(action==='done'){
      return 'outlined';
    }else if(action==='back'){
      return isEmailTemplate || activeStep !== steps.length - 1 ?'outlined':'contained';
    }
  }

  if (status === 'loading' || !data || !mdx) {
    return (
      <Grid container justifyContent='center' alignItems='center' sx={{ width: '100%', height: height }}>
        <Grid item>
          <LoaderSpinner type='Bars' color='#175da8' secondaryColor={'#6abed5'} height={70} width={70} />
        </Grid>
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent sx={{ padding: '2em 2em em 4em',height:'90vh' }}>
          <Stack direction='row' spacing={1} justifyContent='flex-end'>
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                backgroundColor: '#333333',
                padding: '4px',
                '&:hover': {
                  backgroundColor: '#333333',
                },
              }}
            >
              <CloseIcon sx={{ color: '#ffffff', fontSize: '1.2rem' }} />
            </IconButton>
          </Stack>
          <Grid container spacing={1}>
            <Grid item xs={4} container alignItems={'center'} sx={{ paddingRight: '16px' }}>
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={12}>
                  <Typography><span style={{fontWeight:'700'}}>{t('explore.playbooks.executePlay')}:</span> {data?.data?.play_name} </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography color={'#9e9e9e'}>{data?.data?.segment?.segment_name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stepper activeStep={activeStep} orientation='vertical'>
                    {steps.map((step, index) => (
                      <Step key={step.step_name} completed={navigatedSteps.includes(index)}
                            onClick={() => handleStepClick(index)} sx={{
                        cursor: 'pointer', '.Mui-disabled': {
                          cursor: 'pointer',
                        },
                      }}>
                        <StepLabel>
                          {step.step_name}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={8} container spacing={2}>

              <Grid item xs={12} container>
                <Grid item xs={12} sx={{ padding: '0.5rem', height: height - 152, overflowY: 'auto' }}>
                  {mdx && <MDXRemote {...mdx} components={components} />}
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={1} sx={{paddingLeft:'1.5em !important'}}>
                {isEmailTemplate && <Grid item xs={3}>
                  <Button variant='contained' fullWidth sx={{ textTransform: 'none' }} onClick={handleCopy} startIcon={stepsCopied.includes(activeStep) && <TaskAltIcon/>}>
                    {t('explore.playbooks.copyThisEmailTemplate')}
                  </Button>
                </Grid>}
                {activeStep < steps.length - 1 && <Grid item xs={3}>
                  <Button variant={getVariant('next')} fullWidth sx={{ textTransform: 'none' }} onClick={handleNext}>
                    {t('explore.playbooks.next')}
                  </Button>
                </Grid>
                }
                {activeStep > 0 && <Grid item xs={3}>
                  <Button variant={getVariant('back')} fullWidth sx={{ textTransform: 'none' }} onClick={handleBack}>
                    {t('explore.playbooks.back')}
                  </Button>
                </Grid>
                }
                {activeStep === steps.length - 1 && <Grid item xs={3}>
                  <Button variant={getVariant('done')} fullWidth sx={{ textTransform: 'none' }} onClick={handleClose}>
                    {t('explore.playbooks.done')}
                  </Button>
                </Grid>
                }
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ExecutePlayDialog;