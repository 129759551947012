import { useInfiniteQuery } from '@tanstack/react-query';

import api from 'services/api';

const fetchExecutedPlays = async ({ pageParam = 1, filters }) => {
  const query = new URLSearchParams({
    page: pageParam,
  });

  if (filters.search) {
    query.append('segment_name', filters.search);
  }

  if (filters.lifecycle) {
    query.append('lifecycle', filters.lifecycle);
  }

  try {
    const response = await api.get(`api/v1/executed-plays?${query.toString()}`);
    const {
      data: { payload, meta },
    } = await response.json();

    return { data: payload, meta };
  } catch (e) {
    return Promise.reject(e);
  }
};

const useExecutedPlays = ({ enabled = true, filters }) => {
  return useInfiniteQuery({
    queryKey: ['executed-plays', filters],
    queryFn: ({ pageParam = 1 }) => fetchExecutedPlays({ pageParam, filters }),
    enabled,
    getNextPageParam: ({ meta: { next_page } }, allPages) => {
      if (next_page === 0) return undefined;
      return next_page;
    },
  });
};

export default useExecutedPlays;
