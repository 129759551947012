import { useQuery } from '@tanstack/react-query';

import api from 'services/api';

const fetchPlaySteps = async ({ playId }) => {
    const query = new URLSearchParams({
    });

    try {
        const response = await api.get(`api/v1/plays/${playId}?${query.toString()}`);
        const {
            data: { payload, meta },
        } = await response.json();

        return { data: payload, meta };
    } catch (e) {
        return Promise.reject(e);
    }
};

const usePlaySteps = ( playId) => {
    return useQuery({
        queryKey: ['playsteps', playId],
        queryFn: () => fetchPlaySteps({playId }),
        enabled: true
    });
};

export default usePlaySteps;
