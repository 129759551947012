import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import TuneIcon from '@mui/icons-material/Tune';
import MuiPaper from '@mui/material/Paper';
import MuiInputBase from '@mui/material/InputBase';
import MuiSearchIcon from '@mui/icons-material/Search';
import styled from '@mui/system/styled';
import MuiButton from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import { useTranslation } from 'react-i18next';
import MuiDivider from '@mui/material/Divider';
import useDebounce from 'hooks/system/useDebounce';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Grid from '@mui/material/Grid';

const SearchIcon = styled(MuiSearchIcon)(({ theme }) => ({
  marginLeft: '0.4em',
  marginRight: '0.4em',
  color: theme.palette.cml.main,
  opacity: '0.4',
}));

const Divider = styled(MuiDivider)(({ theme }) => ({
  height: '1.8em',
  margin: '0 0.5em 0 .5em',
  borderColor: theme.palette.cml.main,
  opacity: '0.3',
}));

const Paper = styled(MuiPaper)(({ theme }) => ({
  '&.MuiPaper-root': {
    display: 'flex',
    alignItems: 'center',
  },
}));

const InputBase = styled(MuiInputBase)(({ theme }) => ({
  textTransform: 'none',
  width: '22em',

  '& .MuiInputBase-input': {
    fontSize:'0.875rem'
  },
}));

const Button = styled(MuiButton)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    color: theme.palette.cml.main,
    textTransform: 'none',
  },
}));

const DebouncedSearchField = ({
                                onSearch,
                                placeholder = '',
                                id = 'search-input',
                                value = '',
                              }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    onSearch(searchTerm);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  const handleClear = () => {
    setSearchTerm('');
  };

  return (
    <InputBase
      id={id}
      variant='outlined'
      fullWidth={true}
      placeholder={placeholder}
      size='small'
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      type='text'
      endAdornment={
        searchTerm && <InputAdornment position='end'>
          <IconButton
            aria-label='clear search text'
            edge='end'
            disableRipple
            onClick={handleClear}
          >
            <CancelIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};

const FilterButton = ({ placeholder = '', handleSearch, handleFilterOpen, filters }) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const getFilterCount = () => {
    return filters.lifecycle && filters.lifecycle.length;
  };

  return (
    <Paper elevation={0}>
      <Grid container justifyContent={'space-between'} alignItems={'center'} flexWrap={'nowrap'}>
        <Grid item alignItems={'center'} container flexWrap={'nowrap'} xs={4} md={4} lg={6} xl={9}>
          <Grid item container alignItems={'center'}>
            <SearchIcon />
          </Grid>
          <Grid item sx={{paddingRight:'0.5em'}}>
            <DebouncedSearchField placeholder={placeholder} onSearch={handleSearch} />
          </Grid>
        </Grid>
        <Grid item alignItems={'center'} container flexWrap={'nowrap'} justifyContent={'flex-end'}>
          <Grid item>
            <Divider orientation='vertical' />
          </Grid>
          <Grid item>
            <Button
              startIcon={getFilterCount() > 0 ? <Badge badgeContent={1} color='primary'><TuneIcon /></Badge> :
                <TuneIcon />}
              sx={{fontSize:'0.875rem'}}
              disableRipple onClick={handleFilterOpen}>
              {t('filter')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FilterButton;
