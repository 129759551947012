import React from 'react'

const MyPlaybookIcon =({
                                   size = 18,
                                   color = "black"
                                 }) =>{
  return (
    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" >
      <title>My playbooks grey </title>
      <g id="My-playbooks-grey-" stroke="none" fill="evenodd">
        <g id="Group-13-Copy" transform="translate(5.000000, 2.000000)">
          <g id="Group-4" fill="#B9B9B9">
            <path d="M0,8 L0,22.3642612 C4.44666669,22.3642612 8.48444448,24.1271478 11.5,27 C14.5155556,24.1402062 18.5533334,22.3642612 23,22.3642612 L23,8 C18.5533334,8 14.5155556,9.7628866 11.5,12.6357388 C8.48444448,9.7628866 4.44666669,8 0,8 Z" id="Path"></path>
            <path d="M16,4.5 C16,2.01 13.99,0 11.5,0 C9.01,0 7,2.01 7,4.5 C7,6.99 9.01,9 11.5,9 C13.99,9 16,6.99 16,4.5 Z" id="Path"></path>
          </g>
          <g id="Group-8" transform="translate(14.000000, 13.000000)" fill="#FFFFFF">
            <path d="M3.15,0 C1.4,0.175 0,1.6765 0,3.5 C0,5.3235 1.4,6.825 3.15,7 L3.15,4.494 C2.8,4.3505 2.45,3.962 2.45,3.5 C2.45,3.038 2.8,2.6495 3.15,2.506 L3.15,0 Z" id="Path"></path>
            <path d="M4.501,3.15 L7,3.15 C6.832,1.4875 5.6,0.1645 3.85,0 L3.85,2.506 C4.2,2.611 4.382,2.849 4.501,3.15 L4.501,3.15 Z" id="Path"></path>
            <path d="M3.85,4.494 L3.85,7 C5.6,6.8355 6.832,5.5125 7,3.85 L4.501,3.85 C4.382,4.151 4.2,4.389 3.85,4.494 L3.85,4.494 Z" id="Path"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MyPlaybookIcon
