import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import PageContainer from 'components/elements/PageContainer';
import useExecutedPlays from 'hooks/track/useExecutedPlays';
import InfiniteLoaderList from 'components/elements/InfiniteLoadList';
import FilterButton from 'components/elements/FilterButton';
import FilterDropdown from 'components/elements/FilterDropdown';

import ExecutedPlaysView from './executed-plays-view';
import TrackItemCard from './track-item-card';

const AccountActivityItem = ({ name, value }) => {
  return <Grid item>
    <Typography textAlign={'center'} fontSize={'1.2rem'} fontWeight={'700'}>
      {value}
    </Typography>
    <Typography textAlign={'center'} fontSize={'0.9rem'}>
      {name}
    </Typography>
  </Grid>;
};

const Track = () => {
  const { t } = useTranslation();

  return (
    <PageContainer pageTitle={t('explore.track.pageTitle')} pageSubTitle={t('explore.track.pageSubTitle')}>
      <TrackPage />
    </PageContainer>
  );
};


const TrackPage = ({ containerHeight }) => {
  const { t } = useTranslation();
  const [executedPlays, setExecutedPlays] = useState([]);
  const [activitySummary, setActivitySummary] = useState(null);
  const [selectedPlay, setSelectedPlay] = useState(null);
  const [filters, setFilters] = useState({ search: '', lifecycle: [] });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { data, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = useExecutedPlays({ filters });

  useEffect(() => {
    if (data) {
      const executedPlayList = data.pages.reduce((accum, curr) => {
        return [...accum, ...curr.data];
      }, []);
      setExecutedPlays(executedPlayList);
      if (executedPlayList.length > 0) {
        setSelectedPlay(executedPlayList[0]);
      }else{
        setSelectedPlay(null);
      }
      if (data.pages.length > 0) {
        setActivitySummary({
          executed_plays_monitored: data.pages[0].meta.executed_plays_monitored,
          executed_play_actions_taken: data.pages[0].meta.executed_play_actions_taken,
          total_kpis_tracked: data.pages[0].meta.total_kpis_tracked
        })
      }
    }
  }, [data]);

  const handleItemClick = (value) => {
    setSelectedPlay(value);
  };

  const handleSearch = (value) => {
    setFilters({
      ...filters,
      search: value,
    });
  };

  const handleChangeLifecycle = (value) => {
    setFilters({
      ...filters,
      lifecycle: value,
    });
  };

  const handleFilterOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleFilterClose = (value) => {
    setAnchorEl(null);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Paper
          elevation={0}
          sx={{
            '&.MuiPaper-root': {
              boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.2)',
              height: containerHeight - 32,
              padding: '1em 0',
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ padding: '0 1em 0.5em 1em' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography fontWeight={'700'}>
                      {t('explore.track.currentAccountActivity')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} container spacing={1} justifyContent={'space-between'}>
                    <AccountActivityItem name={'Monitored'} value={activitySummary ? activitySummary.executed_plays_monitored : '-'} />
                    <AccountActivityItem name={'Actions Taken'} value={activitySummary ? activitySummary.executed_play_actions_taken : '-'} />
                    <AccountActivityItem name={'KPIs tracked'} value={activitySummary ? activitySummary.total_kpis_tracked : '-'} />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} sx={{ padding: '0.5em 1em 0 2em !important' }}>
              <Divider sx={{ borderBottom: '1px solid #eaeaea;', width: '100%' }} />
            </Grid>

            <Grid item xs={12} sx={{padding:'0.5em 0.5em 0.5em 1.5em !important'}}>
              <Box sx={{ border: '1px solid #eaeaea;', width: '100%',borderRadius:'0.5em',paddingX:'0.1em' }}>
                <FilterButton placeholder={t('explore.track.searchPlaceholder')}
                              handleSearch={handleSearch}
                              filters={filters}
                              handleFilterOpen={handleFilterOpen}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sx={{ paddingTop: '0 !important',backgroundColor:'#f9f9f9' }}>
              {executedPlays &&
                <InfiniteLoaderList
                  height={containerHeight - 200}
                  data={executedPlays}
                  isFetchingNextPage={isFetchingNextPage}
                  hasNextPage={hasNextPage}
                  fetchNextPage={fetchNextPage}
                  isError={isError}
                  itemTemplate={({ style, data, index }) => <TrackItemCard data={data} style={style} index={index}
                    selected={selectedPlay}
                                                                           lastItem={index === executedPlays.length-1}
                    handleItemClick={handleItemClick} />}
                />
              }
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={9}>
        {selectedPlay && <ExecutedPlaysView play={selectedPlay} height={containerHeight - 32} />}
      </Grid>
      <>{open && <FilterDropdown
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        handleChangeLifecycle={handleChangeLifecycle}
        filters={filters}
      />}</>
    </Grid>
  );
};

export default Track;
