import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from '@mui/system/styled';
import MuiBox from '@mui/material/Box';

import Header from 'components/base/Header';
import SideBar from 'components/base/SideBar';

const Grid = styled(MuiBox)(({ theme }) => ({
    display: 'grid',
    gridTemplate: '"nav header" min-content "nav main" 1fr / min-content 1fr',
    minHeight: '100vh',
}));

const GridNav = styled('nav')(({ theme }) => ({
    gridArea: 'nav',
    height: '100vh',
    backgroundColor: 'white',
}));

const GridHeader = styled('header')(({ theme }) => ({
    gridArea: 'header',
}));

const GridMain = styled('main')(({ theme }) => ({
    gridArea: 'main',
    backgroundColor: '#f9f9f9',
    padding: theme.layouts.main.padding,
    overflowY: 'auto', // this prevents scrollbar squashing the contents
    overflowX: 'hidden',
}));

const MainLayout = () => {
    return (
        <Grid>
            <GridNav>
                <SideBar />
            </GridNav>

            <GridHeader>
                <Header />
            </GridHeader>

            <GridMain>
                <Outlet />
            </GridMain>
        </Grid>
    );
};

export default MainLayout;
