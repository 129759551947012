import React from 'react';
import Grid from '@mui/material/Grid';
import styled from '@mui/system/styled';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const UserName = styled(Typography)(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: '700',
    color: 'black',
}));

const UserDesignation = styled(Typography)(({ theme }) => ({
    fontSize: '0.7rem',
    color: theme.palette.cml.main,
}));

const UserProfile = ({ userName = '', userDesignation = '', userImage = '', handleClick }) => {
    return (
        <Grid container flexWrap="nowrap" alignItems="center" spacing={1}>
            <Grid item>
                <Avatar alt={userName} src={userImage} />
            </Grid>

            <Grid item container flexDirection="column">
                <Grid item container>
                    <UserName>{userName}</UserName>
                    <ArrowDropDownIcon sx={{ '&.MuiSvgIcon-root': { color: 'black' } }} onClick={handleClick} />
                </Grid>
                <Grid item>
                    <UserDesignation>{userDesignation}</UserDesignation>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserProfile;
