import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
    activePageTitle: '',
    initData: null,
};

const appSlice = createSlice({
    name: 'app',
    initialState: INITIAL_STATE,
    reducers: {
        notifyChangePageTitle(state, action) {
            state.activePageTitle = action.payload;
        },

        notifyInitData(state, action) {
            state.initData = action.payload;
        },
    },
});

export const { name, actions, reducer } = appSlice;

export const { notifyChangePageTitle, notifyInitData } = actions;

export default reducer;
