import { Trans } from 'react-i18next';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import TimelineIcon from '@mui/icons-material/Timeline';
import HomeIcon from '@mui/icons-material/Home';
import CommentIcon from '@mui/icons-material/Comment';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import MyPlaybookIcon from '../components/elements/MyPlaybookIcon';

// base routes
export const ROUTE_ROOT = '/';
export const ROUTE_SIGN_IN = '/auth/signin';
export const ROUTE_FORGOT_PASSWORD = '/auth/forgot-password';
export const ROUTE_RESET_PASSWORD = '/auth/reset-password';
export const ROUTE_UN_AUTHORIZED = '/unauthorized';

// explore routes
export const ROUTE_EXPLORE = '/investigate';
export const ROUTE_EXPLORE_PLAYBOOKS = `${ROUTE_EXPLORE}/playbooks`;
export const ROUTE_EXPLORE_TRACK = `${ROUTE_EXPLORE}/track`;

// target routes
export const ROUTE_TARGET = '/target';
export const ROUTE_TARGET_MY_COMPANY = `${ROUTE_TARGET}/myCompany`;
export const ROUTE_TARGET_SEGMENT_PLAYS = `${ROUTE_TARGET}/segmentPlays`;
export const ROUTE_TARGET_TRACK = `${ROUTE_TARGET}/track`;
export const ROUTE_TARGET_PERSONAL = `${ROUTE_TARGET}/personal`;

// ask routes
export const ROUTE_ASK = '/ask';
export const ROUTE_ASK_CHAT = `${ROUTE_ASK}/chat`;

export const pathToIndex = {
    [ROUTE_EXPLORE_PLAYBOOKS]: 0,
    [ROUTE_EXPLORE_TRACK]: 1,
    [ROUTE_TARGET_MY_COMPANY]: 2,
    [ROUTE_TARGET_SEGMENT_PLAYS]: 3,
    [ROUTE_TARGET_TRACK]: 4,
    [ROUTE_TARGET_PERSONAL]: 5,
    [ROUTE_ASK_CHAT]: 6,
};

export const appRoutesGrouped = [
    {
        group: {
            id: 0,
            name: <Trans i18nKey="explore" />,
        },
        items: [
            {
                id: pathToIndex[ROUTE_EXPLORE_PLAYBOOKS],
                path: ROUTE_EXPLORE_PLAYBOOKS,
                displayName: <Trans i18nKey="explore.playbooks" />,
                icon: <LocalLibraryIcon />,
            },
            {
                id: pathToIndex[ROUTE_EXPLORE_TRACK],
                path: ROUTE_EXPLORE_TRACK,
                displayName: <Trans i18nKey="explore.track" />,
                icon: <TimelineIcon />,
            },
        ],
    },
    {
        group: {
            id: 1,
            name: <Trans i18nKey="target" />,
        },
        items: [
            {
                id: pathToIndex[ROUTE_TARGET_MY_COMPANY],
                path: ROUTE_TARGET_MY_COMPANY,
                displayName: <Trans i18nKey="target.myCompany" />,
                icon: <HomeIcon />,
            },
            {
                id: pathToIndex[ROUTE_TARGET_SEGMENT_PLAYS],
                path: ROUTE_TARGET_SEGMENT_PLAYS,
                displayName: <Trans i18nKey="target.myPlaybook" />,
                icon: <MyPlaybookIcon />,
            },
            {
                id: pathToIndex[ROUTE_TARGET_TRACK],
                path: ROUTE_TARGET_TRACK,
                displayName: <Trans i18nKey="target.track" />,
                icon: <TimelineIcon />,
            },
            {
                id: pathToIndex[ROUTE_TARGET_PERSONAL],
                path: ROUTE_TARGET_PERSONAL,
                displayName: <Trans i18nKey="target.personal" />,
                icon: <SentimentSatisfiedIcon />,
            },
        ],
    },
    {
        group: {
            id: 2,
            name: <Trans i18nKey="ask" />,
        },
        items: [
            {
                id: pathToIndex[ROUTE_ASK_CHAT],
                path: ROUTE_ASK_CHAT,
                displayName: <Trans i18nKey="ask.chat" />,
                icon: <CommentIcon />,
            },
        ],
    },
];
