import React from 'react';
import { Outlet } from 'react-router-dom';

import UnAuthorized from 'components/base/UnAuthorized';
import usePermissions from 'hooks/system/usePermissions';

const AuthorizedRoute = ({ action, roleMappings }) => {
    // const { hasPermission } = usePermissions(roleMappings);

    // return hasPermission(action) ? <Outlet /> : <UnAuthorized />;
    return <Outlet />;
};

export default AuthorizedRoute;
