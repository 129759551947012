import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import PlaybookItemActions from './playbook-item-actions';
import PlaysTable from './playbook-item-table';
import styled from '@mui/system/styled';
import Box from '@mui/material/Box';

const CardRoot = styled(Box)(() => ({
  padding: '2px 0 16px 0',
}));

const PlaybookItemCard = ({ data, handleBookmarkClick, handleReplyClick, handleExecutePlayClick, handleReferencesClick }) => {
  return <CardRoot>
    <Paper
      elevation={0}
      sx={{ '&.MuiPaper-root': { boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.2)' } }}
    >
      <Grid container sx={{ padding: '1em' }}>
        <Grid item xs={2} sx={{ paddingRight: '40px' }}>
          <PlaybookItemActions
            segment={data.segment}
            handleBookmarkClick={handleBookmarkClick}
            handleReplyClick={handleReplyClick}
            handleReferencesClick={handleReferencesClick}
          />
        </Grid>
        <Grid item xs={10}>
          <PlaysTable
            plays={data.plays}
            handleExecutePlayClick={handleExecutePlayClick}
          />
        </Grid>
      </Grid>
    </Paper>
  </CardRoot>;
};

export default PlaybookItemCard;