import Grid from '@mui/material/Grid';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Box from '@mui/material/Box';
import React from 'react';

const CustomProgressBar = ({ total, current = 0, color = '#009f00' }) => {
  return <Grid container spacing={1} flexWrap={'nowrap'}>
    <Grid item sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <CheckCircleOutlineIcon sx={{ color, zIndex: 9 }} />
      <Box sx={{
        backgroundColor: current >= 1 ? color : '#e7e7e7',
        height: '12px',
        width: '100%',
        marginLeft: '-4px'
      }}></Box>
    </Grid>
    {Array.from({ length: total - 1 }).map((it, index) =>
      <Grid item sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <Box sx={{
          backgroundColor: current >= index + 2 ? color : '#e7e7e7',
          height: '12px',
          width: '100%'
        }}></Box>
      </Grid>
    )
    }
  </Grid>
}

export default CustomProgressBar