import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import cuid from 'cuid';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import NorthIcon from '@mui/icons-material/North';
import styled from '@mui/system/styled';

import CustomTooltip from 'components/elements/CustomTooltip';

import KpiChart from './kpi-chart';
import MuiHelpOutlineIcon from '@mui/icons-material/HelpOutline';

const CardRoot = ({ children }) => {
  return <Box sx={{
    padding: '2px 0 16px 0',
    width: '100%',
  }}>
    <Paper
      elevation={0}
      sx={{ '&.MuiPaper-root': { boxShadow: '1px 1px 2px 2px rgba(0, 0, 0, 0.2)', height: '100%' } }}
    >
      {children}
    </Paper>
  </Box>;
};

const KeyLearningText = styled(Typography)(({ expanded = false }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
  fontSize: '0.9rem',
}));

const HelpOutlineIcon = styled(MuiHelpOutlineIcon)(({ theme }) => ({
  fontSize: 'small',
  color:'#696969'
}));


const KeyLearnings = ({ data, play }) => {
  const [vertical, setVertical] = useState('');
  const initData = useSelector((state) => state.app.initData);

  useEffect(() => {
    if (initData && initData.verticals && initData.verticals.length > 0) {
      setVertical(initData.verticals[0]);
    }
  }, [initData, data, play]);

  const csmText = `Customers experience ${Math.abs(data.change.value).toFixed(2)} 
  ${data.change.direction === 'POSITIVE' ? 'increase' : 'decrease'} in ${data.kpi.kpi_name} 
  post intervention relative to the comparison group. The result is statistically ${data.p_value <= 0.05 ? 'significant' : 'insignificant'}.`;

  const tooltip = `difference-in-difference in ${data.kpi.kpi_name} = ${Math.abs(data.change.value).toFixed(2)} (p value = ${data.p_value.toFixed(3)})`;

  const higherEdText = `Students experience ${Math.abs(data.change.value).toFixed(2)} 
  ${data.change.direction === 'POSITIVE' ? 'increase' : 'decrease'} in ${data.kpi.kpi_name} 
  post intervention relative to the comparison group. The result is statistically ${data.p_value <= 0.05 ? 'significant' : 'insignificant'}.`;

  switch (vertical) {
    case 'CSM':
      return <CustomTooltip title={tooltip}><KeyLearningText>
        {csmText}
      <span style={{ verticalAlign:'-0.175em', paddingLeft:'0.5em'}}><HelpOutlineIcon /></span></KeyLearningText></CustomTooltip>;
    case 'HIGHER_ED':
      return <CustomTooltip title={tooltip}><KeyLearningText>
        {higherEdText}
        <span style={{ verticalAlign:'-0.175em', paddingLeft:'0.5em'}}><HelpOutlineIcon /></span></KeyLearningText></CustomTooltip>;
    default:
      return null;
  }
};

const GraphCard = ({ data, size = 4, index, play }) => {
  const { t } = useTranslation();
  return <Grid item xs={size} container key={index}>
    <CardRoot>
      <Grid container sx={{ padding: '1em' }} alignItems='stretch'>
        <Grid item xs={12}>
          <Typography fontWeight={'700'}>{data.kpi.kpi_name}</Typography>
        </Grid>

        <Grid item xs={12} container>
          <Grid item>
            <Typography>
              <span style={{
                fontWeight: 700,
                fontSize: '1.875rem',
              }}>{Math.abs(data.change.value).toFixed(2)}</span></Typography>
          </Grid>
          <Grid item sx={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '0.5em' }}>
            {data.change.direction === 'POSITIVE' &&
              <NorthIcon sx={{ transform: `rotate(0deg)`, color: '#009f00', fontSize: '1rem' }} />}
            {data.change.direction === 'NEGATIVE' &&
              <NorthIcon sx={{ transform: `rotate(180deg)`, color: '#e02d2d', fontSize: '1rem' }} />}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography fontSize={'0.9rem'}><KeyLearnings data={data} play={play} /></Typography>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '1em' }}>
          <KpiChart xAxis={data.x} pilot={data.pilot} control={data.control} key={cuid()} name={data.kpi.kpi_name} dateExecuted={play.executed_date} />
        </Grid>
      </Grid>
    </CardRoot>
  </Grid>;
};

export default GraphCard;