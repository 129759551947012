import React from 'react';

const WorkshopIcon = ({
                              size = 18,
                              color = 'black',
                            }) => {
  return (
    <svg viewBox='0 0 24 24' width={'4rem'} height={'4rem'} className={'MuiSvgIcon-root'}>
        <title>workshop icon</title>
        <g id="workshop-icon" stroke="none" >
          <g id="co_present_FILL0_wght400_GRAD0_opsz24" transform="translate(1.000000, 2.500000)" >
            <path d="M20,18 L20,2 L2,2 L2,10 L0,10 L0,2 C0,1.45 0.195833333,0.979166667 0.5875,0.5875 C0.979166667,0.195833333 1.45,0 2,0 L20,0 C20.55,0 21.0208333,0.195833333 21.4125,0.5875 C21.8041667,0.979166667 22,1.45 22,2 L22,16 C22,16.55 21.8041667,17.0208333 21.4125,17.4125 C21.0208333,17.8041667 20.55,18 20,18 Z M8,11 C6.9,11 5.95833333,10.6083333 5.175,9.825 C4.39166667,9.04166667 4,8.1 4,7 C4,5.9 4.39166667,4.95833333 5.175,4.175 C5.95833333,3.39166667 6.9,3 8,3 C9.1,3 10.0416667,3.39166667 10.825,4.175 C11.6083333,4.95833333 12,5.9 12,7 C12,8.1 11.6083333,9.04166667 10.825,9.825 C10.0416667,10.6083333 9.1,11 8,11 Z M8,9 C8.55,9 9.02083333,8.80416667 9.4125,8.4125 C9.80416667,8.02083333 10,7.55 10,7 C10,6.45 9.80416667,5.97916667 9.4125,5.5875 C9.02083333,5.19583333 8.55,5 8,5 C7.45,5 6.97916667,5.19583333 6.5875,5.5875 C6.19583333,5.97916667 6,6.45 6,7 C6,7.55 6.19583333,8.02083333 6.5875,8.4125 C6.97916667,8.80416667 7.45,9 8,9 Z M0,19 L0,16.2 C0,15.6333333 0.145833333,15.1125 0.4375,14.6375 C0.729166667,14.1625 1.11666667,13.8 1.6,13.55 C2.63333333,13.0333333 3.68333333,12.6458333 4.75,12.3875 C5.81666667,12.1291667 6.9,12 8,12 C9.1,12 10.1833333,12.1291667 11.25,12.3875 C12.3166667,12.6458333 13.3666667,13.0333333 14.4,13.55 C14.8833333,13.8 15.2708333,14.1625 15.5625,14.6375 C15.8541667,15.1125 16,15.6333333 16,16.2 L16,19 L0,19 Z M2,17 L14,17 L14,16.2 C14,16.0166667 13.9541667,15.85 13.8625,15.7 C13.7708333,15.55 13.65,15.4333333 13.5,15.35 C12.6,14.9 11.6916667,14.5625 10.775,14.3375 C9.85833333,14.1125 8.93333333,14 8,14 C7.06666667,14 6.14166667,14.1125 5.225,14.3375 C4.30833333,14.5625 3.4,14.9 2.5,15.35 C2.35,15.4333333 2.22916667,15.55 2.1375,15.7 C2.04583333,15.85 2,16.0166667 2,16.2 L2,17 Z" id="Shape"></path>
          </g>
        </g>
      </svg>
  );
};

export default WorkshopIcon;
