import React from 'react';
import Box from '@mui/material/Box';

const Conversation = ({children}) => {

  return (
    <Box sx={{width: '100%', margin: '1em 0', padding: '1em', border: '#eaeaea solid 0.5px', borderRadius: '8px'}}>
      {children}
    </Box>
  );
};

export default Conversation;