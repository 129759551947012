import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { Checkbox, FormControlLabel, FormGroup, Popover } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';

const FilterDropdown = ({
                          onClose,
                          open,
                          anchorEl,
                          handleChangeLifecycle,
                          filters,
                        }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [lifecycles, setLifecycles] = useState([]);
  const [selected, setSelected] = useState([...filters.lifecycle]);

  const initData = useSelector((state) => state.app.initData);

  useEffect(() => {
    if (initData && initData.segment_lifecycles && initData.segment_lifecycles.length > 0) {
      setLifecycles(initData.segment_lifecycles);
    }
  }, [initData]);

  useEffect(() => {
    handleChangeLifecycle(selected);
  }, [selected]);

  const handleChange = (event) => {
    let selectedValues = [...selected];
    if (event.target.checked) {
      selectedValues.push(event.target.name);
    } else {
      selectedValues = selected.filter(v => v !== event.target.name);
    }
    setSelected(selectedValues);
  };

  const handleClearAll = () => {
    setSelected([])
  }

  return (
    <Popover id={'tag-dropdown'} open={open} anchorEl={anchorEl}
             anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'center',
             }}
             transformOrigin={{
               vertical: 'top',
               horizontal: 'center',
             }}
             onClose={() => onClose()}>
      <Paper
        elevation={3}
        style={{
          padding: theme.spacing(2),
          maxWidth: '400px',
          minWidth: '400px',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: theme.spacing(1),
          maxHeight: `600px`,
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <Grid item container xs={12} spacing={1}>
          <Grid item xs={12} container spacing={2} justifyContent={'space-between'}>
            <Grid item xs={4}>
              <Typography fontWeight='700'>{t('explore.playbooks.filterBy')}</Typography>
            </Grid>
            {selected.length>0 &&<Grid item onClick={handleClearAll} sx={{cursor:'pointer'}}>
              <Typography>{t('explore.playbooks.clearAll')}</Typography>
            </Grid>
            }
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '1em !important' }}>
            <Divider sx={{ borderBottom: '2px solid #e4e4e4;', width: '100%' }} />
          </Grid>
          <Grid item xs={4} sx={{ paddingTop: '1em !important' }}>
            <Typography>{t('explore.playbooks.lifecycle')}</Typography>
          </Grid>
          <Grid item xs={8}>
            <FormGroup>
              {lifecycles.map(lifecycle => (
                <FormControlLabel
                  control={<Checkbox id={lifecycle} name={lifecycle} checked={selected.includes(lifecycle)}
                                     onChange={handleChange} />} label={lifecycle} />
              ))}
            </FormGroup>
          </Grid>
        </Grid>

      </Paper>
    </Popover>
  );
};

export default FilterDropdown;
