import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useExecutedPlayKpis from 'hooks/track/useExecutedPlayKpis';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import CustomTooltip from 'components/elements/CustomTooltip';

import GraphCard from './graph-card';

const ExecutedPlayHeader = () => {
  const [vertical, setVertical] = useState('');
  const initData = useSelector((state) => state.app.initData);

  useEffect(() => {
    if (initData && initData.verticals && initData.verticals.length > 0) {
      setVertical(initData.verticals[0]);
    }
  }, [initData]);

  switch (vertical) {
    case 'CSM':
      return <Typography sx={{ borderBottom: '2px solid black;', paddingBottom: '0.5em', fontSize: '1.2rem' }}>
        Discover how KPI trend plots can help you optimize your customer success strategies</Typography>;
    case 'HIGHER_ED':
      return <Typography sx={{ borderBottom: '2px solid black;', paddingBottom: '0.5em', fontSize: '1.2rem' }}>
        Discover how KPI trend plots can help you unlock your students' true potential
      </Typography>;
    default:
      return null;
  }
};

const KeyLearnings = ({ play, kpis }) => {
  const [vertical, setVertical] = useState('');
  const initData = useSelector((state) => state.app.initData);

  useEffect(() => {
    if (initData && initData.verticals && initData.verticals.length > 0) {
      setVertical(initData.verticals[0]);
    }
  }, [initData, play]);

  switch (vertical) {
    case 'CSM':
      return <Grid container>
        <Grid item xs={12}>
          <Typography>
            <span style={{ fontWeight: 700 }}>Key learnings: </span>
            KPIs for customers in the {play.play.play_name} trend as follows post intervention relative to
            the {play.segment.segment_name}:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ol style={{ paddingLeft: '1em', paddingTop: '0.5em', margin: 0 }}>
            {kpis.map((kpi, index) => (
              <li
                key={index}>{kpi.kpi.kpi_name}: {kpi.change.direction === 'POSITIVE' ? 'increase' : 'decrease'} of {Math.abs(kpi.change.value).toFixed(2)} </li>
            ))}
          </ol>
        </Grid>
      </Grid>;
    case 'HIGHER_ED':
      return <Grid container>
        <Grid item xs={12}>
          <Typography>
            <span style={{ fontWeight: 700 }}>Key learnings: </span>
            KPIs for students in the {play.play.play_name} trend as follows post intervention relative to
            the {play.segment.segment_name}:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ol style={{ paddingLeft: '1em', paddingTop: '0.5em', margin: 0 }}>
            {kpis.map((kpi, index) => (
              <li
                key={index}>{kpi.kpi.kpi_name}: {kpi.change.direction === 'POSITIVE' ? 'increase' : 'decrease'} of {Math.abs(kpi.change.value).toFixed(2)} </li>
            ))}
          </ol>
        </Grid>
      </Grid>;
    default:
      return null;
  }
};

const CardRoot = ({ children, height }) => {
  return <Paper
    elevation={0}
    sx={{
      '&.MuiPaper-root': {
        boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.2)',
        height,
        padding: '1em',
      },
    }}
  >
    {children}
  </Paper>;
};

const ExecutedPlaysView = ({ play, height }) => {
  const { t } = useTranslation();
  const [kpis, setKpis] = useState([]);
  const { data, status, isError, error } = useExecutedPlayKpis(play.run_id);

  useEffect(() => {
    if (data && data.data) {
      setKpis(data.data);
    }
  }, [data]);

  if (status === 'loading' || !data) {
    return (
      <CardRoot height={height}>
        <Grid container justifyContent='center' alignItems='center' sx={{ width: '100%', height: height }}>
          <Grid item>
            <LoaderSpinner type='Bars' color='#175da8' secondaryColor={'#6abed5'} height={70} width={70} />
          </Grid>
        </Grid>
      </CardRoot>
    );
  }

  return <CardRoot height={height}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ExecutedPlayHeader />
      </Grid>
      <Grid item xs={12}>
        <KeyLearnings play={play} kpis={kpis} />
      </Grid>

      <Grid item xs={12} container spacing={1.5}>
        <Grid item>
          <Typography>{t('explore.track.legend')}</Typography>
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ backgroundColor: '#13a6fe', width: '16px', height: '16px', borderRadius: '100%' }}></Box>
          <Typography sx={{ paddingLeft: '0.5em' }}>{t('explore.track.participants')}</Typography>
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ backgroundColor: '#c7c7c7', width: '16px', height: '16px', borderRadius: '100%' }}></Box>
          <Typography sx={{ paddingLeft: '0.5em' }}>{t('explore.track.nonParticipants')}</Typography>
        </Grid>
        <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
          <CustomTooltip title={''}>
            <HelpOutlineIcon fontSize='1.6rem' sx={{ '&.MuiSvgIcon-root': { color: '#666666' } }} />
          </CustomTooltip>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ paddingLeft: '0 !important' }}>
        <Box sx={{ height: height - 296, overflowY: 'auto', padding: '0 0 1em 1em' }}>
          <Grid container alignItems='stretch' spacing={2}>
            {kpis.map((kpi, index) => (
              <GraphCard data={kpi} index={index} play={play} />
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  </CardRoot>;
};

export default ExecutedPlaysView;