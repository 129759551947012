import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

import useReferences from 'hooks/playbooks/useReferences';

const ReferenceItem = ({ data }) => {
  return <Grid item container xs={12} spacing={2}>
    <Grid item xs={12}>
      {data.link ? <Link href={data.link} rel='noreferrer' target='_blank'>{data.reference_name}</Link> :
        <Typography>{data.reference_name}</Typography>}
      <Typography sx={{ fontWeight: 'bolder' }}>Findings: {data.findings}</Typography>
    </Grid>
    <Grid item xs={12} sx={{ paddingTop: '1.5em !important' }}>
      <Divider sx={{ borderBottom: '2px solid #e4e4e4;', width: '100%' }} />
    </Grid>
  </Grid>;
};

const AdditionalArticleItem = ({ data }) => {
  return <Grid item container xs={12} spacing={2}>
    <Grid item xs={12}>
      {data.link ? <Link href={data.link} rel='noreferrer' target='_blank'>{data.reference_name}</Link> :
        <Typography>{data.reference_name}</Typography>}
    </Grid>
  </Grid>;
};

const ReferencesDialog = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const [references, setReferences] = useState([]);
  const [additionalArticles, setAdditionalArticles] = useState([]);
  const { data, isError, error } = useReferences({ segmentId: open.segment_id });

  useEffect(() => {
    if (data) {
      setReferences(data.data.references);
      setAdditionalArticles(data.data.additional_articles);
    }
  }, [data]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={'xl'}
      >
        <DialogContent sx={{ padding: '2rem', minHeight: '90vh' }}>
          <Stack direction='row' spacing={1} justifyContent='flex-end'>
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                backgroundColor: '#333333',
                padding: '4px',
                '&:hover': {
                  backgroundColor: '#333333',
                },
              }}
            >
              <CloseIcon sx={{ color: '#ffffff', fontSize: '1.2rem' }} />
            </IconButton>
          </Stack>
          <Grid container spacing={4}>
            <Grid item xs={11} container spacing={2}>
              <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography sx={{ fontWeight: '700', fontSize: '1.2rem' }}>{t('explore.playbooks.references')}</Typography>
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: '4px !important' }}>
                <Divider sx={{ borderBottom: '2px solid black;', width: '100%' }} />
              </Grid>
            </Grid>

            <Grid item xs={11} container spacing={3}>
              <Grid item xs={3}>
                <Typography color={'#9e9e9e'}>{t('explore.playbooks.segment')}</Typography>
                <Typography sx={{ fontWeight: '700' }}>{open?.segment_name}</Typography>
              </Grid>
              <Grid item xs={9} container spacing={1} sx={{
                paddingTop: '3em !important'
              }}>
                <Grid item xs={12} container spacing={2} sx={{overflowY: 'auto',
                  overflowX: 'hidden', maxHeight: 'calc(100vh - 224px)'}}>
                  <Grid item xs={12} container spacing={2} sx={{paddingTop: '0 !important'}}>
                    <>{references.map(item => (
                      <ReferenceItem data={item} />
                    ))}</>
                  </Grid>
                  {additionalArticles.length > 0 && <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: 'bolder' }}>{t('explore.playbooks.additionalArticles')}</Typography>
                    </Grid>
                    <Grid item xs={12} container spacing={2}>
                      <>{additionalArticles.map(item => (
                        <AdditionalArticleItem data={item} />
                      ))}</>
                    </Grid>
                    <Grid item xs={12} sx={{ paddingTop: '1.5em !important' }}>
                      <Divider sx={{ borderBottom: '2px solid #e4e4e4;', width: '100%' }} />
                    </Grid>
                  </Grid>
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default ReferencesDialog;