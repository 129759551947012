import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const OrderedListItem = ({ id, title, description, color = '#f28c37' }) => (
  <Box sx={{ margin: '0 0 1.5em 1em' }}>
    <Grid sx={{
      border: '1px solid #eaeaea',
      padding: '1em !important',
      borderRadius: '8px'
    }} item container
      spacing={2}
      alignItems={'center'}
      flexWrap={'nowrap'}>
      <Grid item sx={{padding: '0 !important'}}>
        <Avatar sx={{ width: 20, height: 20, backgroundColor: color, fontSize: '0.8rem', fontWeight: 700 }}>
          {id}
        </Avatar>
      </Grid>
      <Grid item sx={{paddingTop: '0 !important'}}>
        <Typography>{title && <span style={{ fontWeight: 700 }}>{title}: </span>}{description}</Typography>
      </Grid>
    </Grid></Box>
);

export default OrderedListItem;