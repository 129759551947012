import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import api from 'services/api';

const fetchReferences = async ({segmentId }) => {
    const query = new URLSearchParams({
    });

    if (segmentId) {
        query.append('segment', segmentId);
    }

    try {
        const response = await api.get(`api/v1/segment-references?${query.toString()}`);
        const {
            data: { payload, meta },
        } = await response.json();

        return { data: payload, meta };
    } catch (e) {
        return Promise.reject(e);
    }
};

const useReferences = ({ segmentId,  enabled= true,}) => {
    return useQuery({
        queryKey: ['references', segmentId],
        queryFn: () => fetchReferences({ segmentId }),
        enabled
    });
};

export default useReferences;
