import { useQuery } from '@tanstack/react-query';

import api from 'services/api';

const fetchExecutedPlaySteps = async ({ runId }) => {
  const query = new URLSearchParams({});

  try {
    const response = await api.get(`api/v1/executed-plays/${runId}?${query.toString()}`);
    const {
      data: { payload, meta },
    } = await response.json();

    return { data: payload, meta };
  } catch (e) {
    return Promise.reject(e);
  }
};

const useExecutedPlayKpis = (runId) => {
  return useQuery({
    queryKey: ['executed-play-kpis', runId],
    queryFn: () => fetchExecutedPlaySteps({ runId }),
    enabled: true,
    staleTime: 0,
  });
};

export default useExecutedPlayKpis;
