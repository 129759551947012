import React from 'react';
import Typography from '@mui/material/Typography';

const Paragraph = ({ children }) => {

  return (
    <Typography variant={'body1'} fontSize={'1rem'}>
      {children}
    </Typography>
  );
};

export default Paragraph