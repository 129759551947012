import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const ConversationListItem = ({color = '#000000', author, conversation,reply=false}) => (
  <Grid sx={{padding: '0.5em 0.125em'}} item container>
    <Grid item container flexWrap={'nowrap'}>
      <Typography><span style={{ fontWeight: 700, color: reply?'#000000':color }}>{author}: </span>"{conversation}"</Typography>
    </Grid>
  </Grid>
);

export default ConversationListItem;