import React from 'react';

const RecommendationIcon = ({
                        size = 18,
                        color = 'black',
                      }) => {
  return (
    <svg viewBox='0 0 32 32' width={'4rem'} height={'4rem'} className={'MuiSvgIcon-root'}>
      <title>recommendation plan</title>
      <g id="recommendation-plan-icon">
        <g id="co_present_FILL0_wght400_GRAD0_opsz24" transform="translate(4.000000, 4.000000)" >
          <path d="M8.49,14.7 L12,12.57 L15.51,14.7 L14.58,10.71 L17.7,8.01 L13.59,7.68 L12,3.9 L10.41,7.68 L6.3,8.01 L9.42,10.71 L8.49,14.7 Z M0,24 L0,2.4 C0,1.74 0.235,1.175 0.705,0.705 C1.175,0.235 1.74,0 2.4,0 L21.6,0 C22.26,0 22.825,0.235 23.295,0.705 C23.765,1.175 24,1.74 24,2.4 L24,16.8 C24,17.46 23.765,18.025 23.295,18.495 C22.825,18.965 22.26,19.2 21.6,19.2 L4.8,19.2 L0,24 Z M3.78,16.8 L21.6,16.8 L21.6,2.4 L2.4,2.4 L2.4,18.15 L3.78,16.8 Z M2.445,16.8 L2.445,2.4 L2.445,16.8 Z" id="Shape"></path>
        </g>
      </g>
    </svg>
  );
};

export default RecommendationIcon;
