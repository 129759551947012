import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';

const NumberedList = ({ items = [] }) => {
    const theme = useTheme();

    return (
        <List dense>
            {items.map((item, idx) => (
                <ListItem sx={{ '&.MuiListItem-root': { paddingLeft: '0' } }}>
                    <ListItemAvatar sx={{ '&.MuiListItemAvatar-root': { minWidth: '2em' } }}>
                        <Avatar sx={{ width: 20, height: 20, backgroundColor: `${theme.palette.cml.main}`, fontSize: '0.8rem' }}>
                            {idx + 1}
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item['step_name']} />
                </ListItem>
            ))}
        </List>
    );
};

export default NumberedList;
