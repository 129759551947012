import React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const EmailTemplate = ({ children, subject }) => {
  return (
    <Box sx={{ width: '100%', margin: '0.5em 0', padding: '1em', border: '#eaeaea solid 0.5px', borderRadius: '8px' }} id='email-template'>
      <Typography variant={'body1'} gutterBottom paddingBottom={'0.5em'}>
        Subject: {subject}
      </Typography>
      {children}
    </Box>
  );
};

export default EmailTemplate;