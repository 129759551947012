import React from 'react';
import Grid from '@mui/material/Grid';
import MuiTypography from '@mui/material/Typography';
import NorthIcon from '@mui/icons-material/North';

const PotentialImpact = ({ roi = [] }) => {
  return (
    <Grid container flexDirection='column' spacing={2} sx={{paddingRight:'1em'}}>
      {roi.map((data) => (
          <Grid item container spacing={1} flexWrap='nowrap' alignItems={'center'}>
            <Grid item container alignItems={'center'} sx={{width:'2em'}}>
              <NorthIcon sx={{ transform: `rotate(${data.direction * 90}deg)`, color: '#009f00', fontSize: '1rem','&.MuiSvgIcon-root':{
                  stroke: '#009f00',
                strokeWidth: 1.5
              }
                }} />
            </Grid>
            <Grid item sx={{padding:'0 0 0 0 1.125em !important',}}>
              <MuiTypography fontSize={'0.875rem'} sx={{width:'auto'}}>{data[['kpi']]}</MuiTypography>
            </Grid>
          </Grid>
      ))}
    </Grid>
  );
};

export default PotentialImpact;
