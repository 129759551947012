import React, { useEffect, useState, useMemo } from 'react'
import * as jsxRuntime from 'react/jsx-runtime'
import * as mdx from '@mdx-js/react'

export function MDXRemote({
                            compiledSource,
                            frontmatter,
                            scope,
                            components = {},
                            lazy,
                          }) {
  const [isReadyToRender, setIsReadyToRender] = useState(
    !lazy || typeof window === 'undefined'
  )

  useEffect(() => {
    if (lazy) {
      const handle = window.requestIdleCallback(() => {
        setIsReadyToRender(true)
      })
      return () => window.cancelIdleCallback(handle)
    }
  }, [])

  const Content= useMemo(() => {
    const fullScope = Object.assign(
      { opts: { ...mdx, ...jsxRuntime } },
      { frontmatter },
      scope
    )
    const keys = Object.keys(fullScope)
    const values = Object.values(fullScope)

    const hydrateFn = Reflect.construct(
      Function,
      keys.concat(`${compiledSource}`)
    )

    return hydrateFn.apply(hydrateFn, values).default
  }, [scope, compiledSource])

  if (!isReadyToRender) {
    return (
      <div dangerouslySetInnerHTML={{ __html: '' }} suppressHydrationWarning />
    )
  }

  const content = (
    <mdx.MDXProvider components={components}>
      <Content />
    </mdx.MDXProvider>
  )

  return lazy ? <div>{content}</div> : content
}