import React from 'react';
import { useSelector } from 'react-redux';

import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import styled from '@mui/system/styled';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useAuth } from 'hooks/system/useAuth';
import UserProfile from 'components/elements/UserProfile';
import AccountsMenu from 'components/elements/AccountsMenu';

const PageTitle = styled(Typography)(({ theme }) => ({
    color: 'black',
    fontFamily: 'Open Sans',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '2.3rem',
    lineHeight: '1.1',

    // [theme.breakpoints.up('lg')]: {
    //     fontSize: '2.7rem',
    // },
}));

const SubPageTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.cml.main,
    fontFamily: 'Open Sans',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '0.8rem',
    paddingTop: '0',

    // [theme.breakpoints.up('lg')]: {
    //     fontSize: '1rem',
    // },
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'drawerOpen',
})(({ theme }) => ({
    padding: theme.spacing(0, 3, 0, 3),
    backgroundColor: 'white',
    boxShadow: 'none',
}));

const Header = () => {
    const activePageTitle = useSelector((state) => state.app.activePageTitle);
    const profile = useSelector((state) => state.auth.profile);
    const roles = useSelector((state) => state.auth.roles);

    const roleName = (roles) => {
        let effective_role =''
        if(roles) {
            if (roles.includes('super_administrator')) {
                effective_role = 'Super Administrator'
            } else if (roles.includes('administrator')) {
                effective_role = 'Administrator'
            } else if (roles.includes('editor')) {
                effective_role = 'Editor'
            } else if (roles.includes('viewer')) {
                effective_role = 'Viewer'
            }
        }
        return effective_role;
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleProfileMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const { keycloak, initialized } = useAuth();

    const handleLogout = async (e) => {
        e.preventDefault();

        if (initialized) {
            keycloak.logout();
        }
    };

    return (
        <AppBar position="static" sx={{ paddingLeft: '0', paddingTop: '0.2em', paddingBottom: '0.6em' }}>
            <Toolbar disableGutters>
                <Grid container flexWrap="nowrap" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <PageTitle data-testid={'page-title'}>{activePageTitle.pageTitle}</PageTitle>
                        <SubPageTitle data-testid={'sub-page-title'}>{activePageTitle.pageSubTitle}</SubPageTitle>
                    </Grid>

                    <Grid item>
                        <UserProfile
                            userName={profile ? (profile.name ?? profile.preferred_username) : ''}
                            userDesignation={roleName(roles)}
                            handleClick={handleProfileMenuClick}
                        />
                        <AccountsMenu
                            handleClose={handleClose}
                            open={open}
                            anchorEl={anchorEl}
                            handleLogout={handleLogout}
                        />
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
