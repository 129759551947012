import Typography from '@mui/material/Typography';
import React from 'react';

const Header2 = ({ children }) => {

  return (
    <Typography variant={'h2'} gutterBottom fontSize={'1rem !important'} padding={'1em 0'} fontWeight={700}>
      {children}
    </Typography>
  );
};

export default Header2;