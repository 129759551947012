import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import PageContainer from 'components/elements/PageContainer';
import FilterButton from 'components/elements/FilterButton';
import InfiniteLoaderList from 'components/elements/InfiniteLoadList';
import FilterDropdown from 'components/elements/FilterDropdown';
import usePlaybooks from 'hooks/playbooks/usePlaybook';

import PlaybookItemCard from './playbook-item-card';
import ExecutePlayDialog from './execute-play-dialog';
import ReferencesDialog from './references-dialog';

const Playbooks = () => {
  const { t } = useTranslation();
  return <PageContainer pageTitle={t('explore.playbooks.pageTitle')} pageSubTitle={t('explore.playbooks.pageSubTitle')}>
    <PlaybooksPage />
  </PageContainer>;
};

const PlaybooksPage = ({ containerHeight }) => {
  const { t } = useTranslation();
  const [flattenedSegmentPlays, setFlattenedSegmentPlays] = useState([]);
  const [filters, setFilters] = useState({ search: '', lifecycle: [] });
  const [executePlayOpen, setExecutePlayOpen] = useState(null);
  const [referencesOpen, setReferencesOpen] = useState(null);
  const { data, isError, error, isFetchingNextPage, hasNextPage, fetchNextPage } = usePlaybooks({ filters });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (data) {
      setFlattenedSegmentPlays(
        data.pages.reduce((accum, curr) => {
          return [...accum, ...curr.data];
        }, []));
    }
  }, [data]);

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  const handleSearch = (value) => {
    setFilters({
      ...filters,
      search: value,
    });
  };

  const handleChangeLifecycle = (value) => {
    setFilters({
      ...filters,
      lifecycle: value,
    });
  };

  const handleFilterOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleFilterClose = (value) => {
    setAnchorEl(null);
  };


  const handleBookmarkClick = () => {
  };

  const handleReplyClick = () => {
  };

  const handleDescriptionClick = () => {
  };

  const handleExecutePlayClick = (playId) => {
    setExecutePlayOpen({ playId });
  };

  const handleExecutePlayClose = () => {
    setExecutePlayOpen(null);
  };

  const handleReferencesClick = (segment) => {
    setReferencesOpen(segment);
  };

  const handleReferencesClose = () => {
    setReferencesOpen(null);
  };

  return (
    <Grid container spacing={2}>
      <Grid item container alignItems='center' justifyContent='space-between'>
        <Grid item>{t('explore.playbooks.actionTitle')}</Grid>
        <Grid item>
          <FilterButton placeholder={t('explore.playbooks.searchPlaceholder')} handleSearch={handleSearch} filters={filters}
                        handleFilterOpen={handleFilterOpen} />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {flattenedSegmentPlays &&
          <InfiniteLoaderList
            height={containerHeight - 80}
            data={flattenedSegmentPlays}
            isFetchingNextPage={isFetchingNextPage}
            hasNextPage={hasNextPage}
            fetchNextPage={fetchNextPage}
            isError={isError}
            itemTemplate={({ style, data, index }) => <PlaybookItemCard data={data} style={style} index={index}
                                                                        handleReplyClick={handleReplyClick}
                                                                        handleDescriptionClick={handleDescriptionClick}
                                                                        handleExecutePlayClick={handleExecutePlayClick}
                                                                        handleBookmarkClick={handleBookmarkClick}
                                                                        handleReferencesClick={handleReferencesClick}
            />
            }
          />
        }
      </Grid>
      <>{executePlayOpen &&
        <ExecutePlayDialog open={executePlayOpen} handleClose={handleExecutePlayClose} height={containerHeight} />}</>
      <>{referencesOpen &&
        <ReferencesDialog open={referencesOpen} handleClose={handleReferencesClose} height={containerHeight} />}</>
      <>{open && <FilterDropdown
        open={open}
        anchorEl={anchorEl}
        onClose={handleFilterClose}
        handleChangeLifecycle={handleChangeLifecycle}
        filters={filters}
      />}</>
    </Grid>
  );
};

export default Playbooks;
