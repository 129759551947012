import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useResizeDetector } from 'react-resize-detector';
import Box from '@mui/material/Box';
import { addDays, format, parseISO } from 'date-fns';

import useDebounce from 'hooks/system/useDebounce';

const KpiChart = ({
  legend1 = '',
  legend2 = '',
  pilot = [],
  control = [],
  xAxis = [],
  key,
  name,
  dateExecuted
}) => {
  const charOptions = () => {
    return {
      chart: {
        height: '200px',
        animation: false,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: null,
      },
      xAxis: {
        gridLineWidth: 0,
        labels: {
          enabled: true,
          formatter: function () {
            return this.value > 0 ? 'after' : 'before';
          },
        },
        plotLines: [{
          color: '#6690a1',
          width: 1,
          value: 0,
          zIndex: 5,
          dashStyle: 'ShortDash',
        }],
        lineWidth: 0,
        tickPositioner: function () {
          const positions = []
          positions.push(Math.ceil((this.dataMax - 0) / 2));
          positions.push(Math.ceil((this.dataMin - 0) / 2));
          return positions;
        },
        plotBands: [{
          color: '#f9f9f9',
          from: xAxis[0],
          to: xAxis.slice(-1)
        }]
      },
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          enabled: true,
          format: '{value}',
        },
        gridLineWidth: 0,
        gridLineColor: 'transparent',
        lineWidth: 0,
        tickWidth: 0,
        tickAmount: 4
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: false,
          },
        },
      },
      tooltip: {
        headerFormat: "",
        useHTML: true,
        formatter: function () {
          return `${name} value of ${this.point.y.toFixed(2)}, ${Math.abs(this.point.x)} days ${this.point.x>0?'after':'before'} intervention`;
        },
      },
    };
  };

  const [options, setOptions] = useState(charOptions());
  const chartRef = useRef(null);
  const { width, ref } = useResizeDetector();
  const chartWidth = useDebounce(width, 200);

  useEffect(() => {
    setOptions({
      ...charOptions(),
      xAxis: [
        {
          tickInterval: xAxis.length / 2,
          type: 'category',
        },
      ],
      series: [
        {
          name: `${legend1}`,
          data: pilot.map((y, index) => {
            return [xAxis[index], y];
          }),
          color: '#13a6fe',
          showInLegend: false,
          marker: {
            enabled: false,
          }
        },
        {
          name: `${legend2}`,
          type: 'line',
          data: control.map((y, index) => {
            return [xAxis[index], y];
          }),
          color: '#c9c9c9',
          showInLegend: false,
          marker: {
            enabled: false,
          }
        },
      ],
    });
  }, [xAxis, pilot, control, legend1, legend2]);

  useEffect(() => {
    if (chartRef) {
      chartRef.current.chart.reflow();
    }
  }, [chartWidth]);

  return (<Box ref={ref}>
    <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} key={key} />
  </Box>
  );
};

export default KpiChart;
