import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const InvalidItemCard = ({ message }) => {
  return (
    <Grid container justify='center' alignItems='center'>
      <Paper>
        <div>
          {message && (
            <Typography variant='h2'>
              <InfoOutlinedIcon />
              {message}
            </Typography>
          )}
        </div>
      </Paper>
    </Grid>
  );
};

export default InvalidItemCard;
