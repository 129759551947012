import React from 'react';
import { Box } from '@mui/material';

const StepBody = ({ children, height }) => {

  return (
    <Box sx={{ width: '100%', paddingBottom: '1em', height: height, overflowY: 'auto' }} id='play-step-body'>
      {children}
    </Box>
  );
};

export default StepBody;
