import React from 'react';
import Grid from '@mui/material/Grid';
import CircleIcon from '@mui/icons-material/Circle';
import MuiTypography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const PlayDifficultyLevel = ({ difficulty }) => {
    const { t } = useTranslation();

    const levels = {
        Low: {
            color: '#009f00',
            displayName: 'explore.playbook.difficulty.simple',
        },
        Medium: {
            color: 'rgb(235,213,3)',
            displayName: 'explore.playbook.difficulty.moderate',
        },
        High: {
            color: 'rgb(202,0,192)',
            displayName: 'explore.playbook.difficulty.moreInvolved',
        },
    };

    return (
        <Grid container alignItems="center" spacing={1} flexWrap='nowrap'>
            <Grid item alignSelf="center" >
                <CircleIcon
                    sx={{
                        '&.MuiSvgIcon-root': { fontSize: '0.7rem', color: levels[difficulty]?.color },
                    }}
                />
            </Grid>
            <Grid item>
                <MuiTypography>{t(levels[difficulty]?.displayName)}</MuiTypography>
            </Grid>
        </Grid>
    );
};

export default PlayDifficultyLevel;
