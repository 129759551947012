import { useQuery } from '@tanstack/react-query';

import api from 'services/api';

const useInit = ({ enabled = true }) => {
    const fetchInit = async () => {
        const query = new URLSearchParams({});

        try {
            const response = await api.get(`api/v1/init?${query.toString()}`);
            const {
                data: { payload, meta },
            } = await response.json();

            return { data: payload, meta };
        } catch (e) {
            return Promise.reject(e);
        }
    };

    return useQuery({
        queryKey: ['init'], queryFn: () => fetchInit(),
        refetchOnWindowFocus: false,
        enabled,
    });
};

export default useInit;
