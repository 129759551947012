import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CustomError from 'components/elements/CustomError';
import LoaderSpinner from 'components/elements/LoaderSpinner';
import useInit from 'hooks/app/useInit';
import AppRouter from 'pages/app';
import { notifyInitData } from 'state/app';

const AppInitializer = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [isCheck, setIsCheck] = useState(false);
    const [hasVertical, setHasVertical] = useState(false);

    const { data, isError, status } = useInit({});

    useEffect(() => {
        if (data && data.data) {
            if (data.data && data.data.verticals && data.data.verticals.length > 0) {
                dispatch(notifyInitData(data.data))
                setHasVertical(true)
                localStorage.setItem('CML_INSIGHTS_ORGANIZATION_VERTICAL', data.data.verticals);
                setIsCheck(true);
            } else {
                setIsCheck(true);
            }

        }
    }, [data]);

    if (isError) {
        return <CustomError message={t('Failed to initialize.')} />;
    } else {
        if (!isCheck || status === 'loading') {
            return (
                <Grid container justifyContent="center" alignItems="center" sx={{ width: '100%', height: '100vh' }}>
                    <Grid item>
                        <LoaderSpinner type="Bars" color="#175da8" secondaryColor={'#6abed5'} height={70} width={70} />
                    </Grid>
                </Grid>
            );
        } else {
            if (!hasVertical) {
                return <CustomError message={t('No verticle found.')} />;
            } else {
                return <AppRouter />;
            }
        }
    }
};

export default AppInitializer;
