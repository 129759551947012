import React from 'react';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';

import BookmarkIcon from '@mui/icons-material/Bookmark';
import ReplyIcon from '@mui/icons-material/Reply';
import MuiTypography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import DescriptionIcon from '@mui/icons-material/Description';

const PlaybookItemActions = ({ segment, handleBookmarkClick, handleReplyClick, handleReferencesClick }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid item container flexDirection='column' justifyContent='space-between' flexWrap='nowrap' height='100%'>
      <Grid item container spacing={3} flexDirection='column' justifyContent='space-between' height='100%' paddingBottom='1em'>
        <Grid item>
          <MuiTypography fontSize='0.9rem' color={theme.palette.cml.main} paddingBottom='0.2em'>
            {t('explore.playbooks.segment')}
          </MuiTypography>
          <MuiTypography fontSize='0.9rem' fontWeight='700'>
            {segment.segment_name}
          </MuiTypography>
        </Grid>
        <Grid item>
          <MuiTypography fontSize='0.9rem' color={theme.palette.cml.main} paddingBottom='0.2em'>
            {t('explore.playbooks.lifecycle')}
          </MuiTypography>
          <MuiTypography fontSize='0.9rem' fontWeight='700'>
            {segment.lifecycle}
          </MuiTypography>
        </Grid>
      </Grid>
      <Grid item container spacing={3}>
        <Grid item>
          <DescriptionIcon sx={{ fontSize: '1rem', color: '#b1b1b1', cursor: 'pointer', '&:hover':{
            color:'#333333'
            } }}
                           onClick={() => handleReferencesClick(segment)} />
        </Grid>
        <Grid item>
          <BookmarkIcon sx={{ fontSize: '1rem', color: '#b1b1b1' }} onClick={handleBookmarkClick} />
        </Grid>
        <Grid item>
          <ReplyIcon
            sx={{
              fontSize: '1rem',
              color: '#b1b1b1',
              transform: 'scaleX(-1)',
            }}
            onClick={handleReplyClick}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlaybookItemActions;
