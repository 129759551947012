import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import LogoutIcon from '@mui/icons-material/Logout';
import styled from '@mui/system/styled';

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
    color: theme.palette.cml.main,
    fontFamily: 'Open Sans',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: '0.9rem',
    lineHeight: '1.8',
    // paddingTop: 0,
    // paddingBottom: 0,

    // [theme.breakpoints.up('lg')]: {
    //     fontSize: '2.7rem',
    // },
}));

const AccountsMenu = ({ anchorEl, open, handleClose, handleLogout }) => {
    const { t } = useTranslation();

    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            paper={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                    <LogoutIcon fontSize="extrasmall" />
                </ListItemIcon>
                {t('logout')}
            </MenuItem>
        </Menu>
    );
};

export default AccountsMenu;
