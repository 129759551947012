import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import styled from '@mui/system/styled';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { format, parseISO } from 'date-fns';

import NumberedList from 'components/elements/NumberedList';
import CustomProgressBar from './custom-progress-bar';

const CardRoot = styled(Box)(({lastItem= false}) => ({
  width: '100%',
  ...(!lastItem && {paddingBottom: '16px'}),
}));

const TrackItemCard = ({ index, data, selected, handleItemClick, active = false,lastItem= false }) => {
  const { t } = useTranslation();
  return <CardRoot onClick={() => handleItemClick(data)} lastItem={lastItem}>
    <Paper
      elevation={0}
      sx={{ '&.MuiPaper-root': { boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.2)' }, borderRadius: 0 }}
    >
      <Grid container sx={{
        padding: '1em', ...(selected && selected.run_id === data.run_id && {
          background: 'aliceblue',
        }),
        cursor: 'pointer',
      }} spacing={1}>
        <Grid item xs={12}>
          <Typography>
            <span style={{ fontWeight: 700 }}>Segment: </span>{data.segment.segment_name}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography>{t('explore.track.trackingProgress')}</Typography>
          <Typography>{data.tracked_kpis} KPIs</Typography>
        </Grid>
        <Grid item xs={12}>
          <CustomProgressBar total={data.tracked_kpis} current={data.tracked_kpis} />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <span style={{ fontWeight: 700 }}>{t('explore.track.playsExecuted')}: </span><span>{data.play.play_name}</span></Typography>
          <NumberedList
            items={data.play.steps} />

        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
          <Typography sx={{ fontSize: '0.75rem', color: '#9e9e9e' }}>{t('explore.track.dateExecuted')}: {' '}
            {format(parseISO(data.executed_date), 'LLL. dd, y')}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
          <Button
            sx={{
              '&.MuiButton-root': {
                '&:hover': { fontWeight: '700' },
                backgroundColor: 'transparent',
                textTransform: 'none',
                paddingRight: '0',
                paddingLeft: '0',
              },
            }}
            endIcon={<KeyboardArrowRightIcon />}
            disableRipple
            onClick={() => handleItemClick(data)}
          >
            {t('explore.track.seeProgress')}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  </CardRoot>;
};

export default TrackItemCard;