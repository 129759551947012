import Typography from '@mui/material/Typography';
import React from 'react';

const Header1 = ({ children }) => {

  return (
    <Typography variant={'h1'} gutterBottom fontSize={'1.2rem !important'} paddingBottom={'0.5em'} fontWeight={700}>
      {children}
    </Typography>
  );
};

export default Header1
