import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Rating from '@mui/material/Rating';
import styled from '@mui/system/styled';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import MuiHelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

import PlayDifficultyLevel from 'components/elements/PlayDifficultyLevel';
import PotentialImpact from 'components/elements/PotentialImpact';
import SuccessMetrics from 'components/elements/SucessMetrics';
import CustomTooltip from 'components/elements/CustomTooltip';

const TableContentRow = styled(TableCell)(({ theme }) => ({
    paddingLeft: '0',
    paddingRight: '40px',
}));

const HelpOutlineIcon = styled(MuiHelpOutlineIcon)(({ theme }) => ({
    fontSize: 'small',
}));

const TableHeader = ({ headerText, iconComponent, width, justifyContent = 'flex-start',lastItem=false, tooltip = '' }) => {
    return (
        <TableCell
            sx={{
                '&.MuiTableCell-root': {
                    width: `${width}`,
                    fontWeight: '700',
                    paddingTop: '0',
                    paddingLeft: '0',
                    ...(!lastItem && {paddingRight: '40px'}),
                    ...(lastItem && {paddingRight: '0'}),
                    borderBottom: '2px solid black;',
                },
            }}
        >
            {iconComponent !== null ? (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: `${justifyContent}` }}>
                    <Box sx={{ paddingRight: '0.8em' }}>{headerText}</Box>
                    <CustomTooltip title={tooltip} arrow>{iconComponent}</CustomTooltip>
                </Box>
            ) : (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box>{headerText}</Box>
                </Box>
            )}
        </TableCell>
    );
};

const PlaysTable = ({ plays = [], handleExecutePlayClick }) => {
    const { t } = useTranslation();
    const [vertical,setVertical] =useState('');

    const initData = useSelector((state) => state.app.initData);

    useEffect(() => {
        if (initData && initData.verticals && initData.verticals.length > 0) {
            setVertical(initData.verticals[0]);
        }
    }, [initData]);

    const getPersona = (vertical)=>{
        switch (vertical) {
            case 'CSM':
                return 'Customers';
            case 'HIGHER_ED':
                return 'Students';
            default:
                return '';
        }
    }

    return (
        <TableContainer component={Paper} elevation={0} sx={{paddingLeft:'0', padding:'0.5em'}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeader
                            headerText={t('explore.playbooks.popularity')}
                            iconComponent={
                                <HelpOutlineIcon fontSize="1.6rem" sx={{ '&.MuiSvgIcon-root': { color: '#666666' } }} />
                            }
                            tooltip={t('explore.playbooks.popularity.tooltip')}
                            width="12%"
                        />
                        <TableHeader headerText={t('explore.playbooks.topPlays')} width="28%" />
                        <TableHeader
                            headerText={t('explore.playbooks.potentialImpact')}
                            iconComponent={
                              <HelpOutlineIcon fontSize="1.6rem" sx={{ '&.MuiSvgIcon-root': { color: '#666666' } }} />
                            }
                            tooltip={t('explore.playbooks.potentialImpact.tooltip',{persona: getPersona(vertical)})}
                            width="20%"
                        />
                        <TableHeader
                            headerText={t('explore.playbooks.successMetrics')}
                            iconComponent={
                                <HelpOutlineIcon fontSize="1.6rem" sx={{ '&.MuiSvgIcon-root': { color: '#666666' } }} />
                            }
                            tooltip={t('explore.playbooks.successMetrics.tooltip')}
                            width="20%"
                        />
                        <TableHeader headerText={t('explore.playbooks.execution')} width="10%" />
                        <TableHeader
                            headerText={t('explore.playbooks.putIntoAction')}
                            width="10%"
                            justifyContent="flex-end"
                            lastItem
                        />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {plays.map((row) => (
                        <TableRow
                            key={row.popularity}
                            sx={{
                                'td, th': { borderBottomColor: '#dddddd' },
                                '&:last-child td, &:last-child th': { border: 0, paddingBottom: '0' },
                            }}
                        >
                            <TableContentRow component="th" scope="row">
                                <Rating value={row.popularity} precision={0.5} readOnly sx={{'& .MuiRating-iconFilled': {
                                        color: '#EBD700',
                                    },
                                    '& .MuiRating-iconHover': {
                                        color: '#EBD700',
                                    },fontSize:'1.25rem'
                                    }} />
                            </TableContentRow>
                            <TableContentRow>
                                {/*<NumberedList items={row.steps} />*/}
                                {row.play_name}
                            </TableContentRow>
                            <TableContentRow>
                                <PotentialImpact roi={row.potential_impact} />
                            </TableContentRow>
                            <TableContentRow>
                                <SuccessMetrics items={row.kpis} />
                            </TableContentRow>
                            <TableContentRow>
                                <PlayDifficultyLevel difficulty={row.execution} />
                            </TableContentRow>
                            <TableContentRow
                                sx={{
                                    '&.MuiTableCell-root': {
                                        width: '10em',
                                    },
                                    paddingRight: '0',
                                }}
                                align="right"
                            >
                                <Button
                                    sx={{
                                        '&.MuiButton-root': {
                                            '&:hover': { fontWeight: '700' },
                                            backgroundColor: 'transparent',
                                            textTransform: 'none',
                                            paddingRight: '0',
                                        },
                                    }}
                                    endIcon={<PlayArrowIcon />}
                                    disableRipple
                                    onClick={() => handleExecutePlayClick(row.play_id)}
                                >
                                    {t('explore.playbooks.executePlay')}
                                </Button>
                            </TableContentRow>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default PlaysTable;
