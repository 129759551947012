import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const UnorderedListItem = ({ color = '#f28c37', title, description }) => (
  <Box sx={{ margin: '0 0 1.5em 1em' }}>
    <Grid sx={{
      border: '1px solid #eaeaea',
      padding: '1em !important',
      borderRadius: '8px'
    }} item container
      spacing={2}
      alignItems={'center'}
      flexWrap={'nowrap'}>
      <Grid item  sx={{padding: '0 !important'}}>
        <Box sx={{ backgroundColor: color, width: '8px', height: '8px', borderRadius: '100%' }}></Box>
      </Grid>
      <Grid item sx={{paddingTop: '0 !important'}}>
        <Typography>{title && <span style={{ fontWeight: 700 }}>{title}: </span>}{description}</Typography>
      </Grid>
    </Grid>
  </Box>
);

export default UnorderedListItem;