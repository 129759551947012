import React from 'react';
import MuiHelpOutlineIcon from '@mui/icons-material/HelpOutline';
import styled from '@mui/system/styled'
import Grid from '@mui/material/Grid';
import CustomTooltip from '../CustomTooltip';

const HelpOutlineIcon = styled(MuiHelpOutlineIcon)(({ theme }) => ({
  fontSize: 'small',
  color:'#696969'
}));

const SuccessMetrics = ({ items = [] }) => {
  return (
    <Grid container spacing={1}>
      {items.map((item) => (
        <Grid item xs={12}>
          <span style={{ paddingRight: '0.7em', fontSize: '0.875rem' }}> {item['kpi_name']} <span style={{ verticalAlign:'-0.1em'}}>
            <CustomTooltip title={item['kpi_description']}>
                          <HelpOutlineIcon />
                        </CustomTooltip></span>
          </span>
        </Grid>
      ))}
    </Grid>
  );
};

export default SuccessMetrics;
