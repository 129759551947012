import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';

const ProgressTracker = ({ total, current, completed = 0, color }) => {

  return <Grid container spacing={1} sx={{ paddingTop: '1em' }}>
    <Grid item xs={12}>
      <Typography>Task {current}/{total}</Typography>
    </Grid>
    <Grid item sx={{ display: 'flex', alignItems: 'center', maxWidth: '120px', width: '100%' }}>
      <CheckCircleOutlineIcon sx={{ color, zIndex: 9999 }} />
      <Box sx={{
        backgroundColor: completed >= 1 ? color : '#e7e7e7',
        height: '12px',
        width: '100%',
        marginLeft: '-4px'
      }}></Box>
    </Grid>
    {Array.from({ length: total - 1 }).map((it, index) =>
      <Grid item sx={{ display: 'flex', alignItems: 'center', maxWidth: '120px', width: '100%' }}>
        <Box sx={{
          backgroundColor: current >= index + 2 ? color : '#e7e7e7',
          height: '12px',
          width: '100%'
        }}></Box>
      </Grid>
    )
    }
    <Grid item xs={12} sx={{ paddingTop: '1em !important' }}>
      <Divider sx={{ borderBottom: '1px solid #eaeaea;', width: '100%' }} />
    </Grid>
  </Grid>
}

export default ProgressTracker;